import React from 'react';

export const AIBotSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_402_5293"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="51"
      height="51"
    >
      <rect x="0.611328" y="0.0762939" width="50" height="50" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_402_5293)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02179 18.2603L8.82954 14.4246C9.77982 15.025 10.4109 16.0848 10.4109 17.2919C10.4109 18.5494 9.72611 19.6469 8.70907 20.2319L9.54687 26.264C10.3384 26.7325 10.8743 27.5875 10.8945 28.5691L16.0378 31.0628C16.5357 30.5554 17.2292 30.2407 17.9962 30.2407C18.0778 30.2407 18.1585 30.2442 18.2383 30.2512C18.2383 30.2512 18.021 30.6008 17.9962 30.8862C17.8348 32.7422 17.9962 35.7279 17.9962 35.7279C17.0328 35.7279 16.1854 35.2314 15.696 34.4803C15.6629 34.4669 15.6301 34.4523 15.5975 34.4365L8.99876 31.2371C8.73193 31.3236 8.44718 31.3704 8.15151 31.3704C6.63626 31.3704 5.40791 30.1421 5.40791 28.6268C5.40791 27.81 5.76481 27.0766 6.33117 26.574L5.50395 20.618C5.4898 20.5162 5.48549 20.4152 5.49029 20.3161C4.38801 19.7568 3.63263 18.6126 3.63263 17.2919C3.63263 16.0848 4.26376 15.025 5.21404 14.4246L7.02179 18.2603Z"
        fill="#61B195"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19775 17.8138C4.56444 19.6472 5.97302 20.0732 5.97302 20.0732L6.94134 26.574C6.61857 27.0936 5.61462 27.469 6.05371 29.353C6.49281 31.2371 8.99902 31.2371 8.99902 31.2371C8.99902 31.2371 8.44744 31.3704 8.15177 31.3704C6.63652 31.3704 5.40815 30.1421 5.40815 28.6268C5.40815 27.81 5.76506 27.0766 6.33142 26.574L5.5042 20.618C5.49005 20.5162 5.48574 20.4152 5.49054 20.3161C4.38826 19.7568 3.63288 18.6126 3.63288 17.2919C3.63288 16.0848 4.26401 15.025 5.21429 14.4246C5.21429 14.4246 3.87496 16.1999 4.19775 17.8138Z"
        fill="#3D725F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9486 31.2524V34.909L38.6263 35.5482C38.1885 35.8439 37.6607 36.0166 37.0927 36.0166C37.0927 36.0166 37.2541 33.0309 37.0927 31.1749C37.0678 30.8895 36.8506 30.5399 36.8506 30.5399C36.9303 30.5329 37.0111 30.5294 37.0927 30.5294C37.8084 30.5294 38.4601 30.8034 38.9486 31.2524Z"
        fill="#61B195"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.6075 50.6812L38.4062 53.4618C37.7789 52.5291 37.6288 51.3048 38.1157 50.2002C38.6229 49.0496 39.6922 48.3215 40.8588 48.1965L42.5252 42.3389C41.9899 41.5909 41.8444 40.5925 42.2218 39.686L38.7073 35.5487C38.2695 35.8444 37.7418 36.017 37.1737 36.017C37.1737 36.017 37.3351 33.0313 37.1737 31.1753C37.1489 30.8899 36.9316 30.5403 36.9316 30.5403C37.0114 30.5334 37.0921 30.5298 37.1737 30.5298C38.203 30.5298 39.0999 31.0966 39.5693 31.935C39.8387 32.0253 40.0876 32.1879 40.2849 32.4202L45.0327 38.0093C45.3117 38.0378 45.5911 38.1098 45.8617 38.2291C47.2482 38.8403 47.8768 40.4597 47.2656 41.8462C46.9362 42.5936 46.3138 43.1208 45.5928 43.3522L43.9474 49.1359C43.9193 49.2348 43.8825 49.3289 43.8381 49.4177C44.6212 50.3741 44.8509 51.7258 44.3182 52.9342C43.8313 54.0389 42.8263 54.754 41.7146 54.9201L41.6075 50.6812Z"
        fill="#61B195"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6266 50.2189C39.5206 48.5768 41.2622 48.468 41.2622 48.468L42.9841 42.3916C42.9217 42.152 42.5215 41.7507 42.4192 41.2619C42.2689 40.5431 42.5806 39.5673 42.5806 39.5673C42.5806 39.5673 41.3702 38.1148 40.0791 36.8237C39.7801 36.5247 38.7073 35.5326 38.7073 35.5326L42.1772 39.7287C41.8544 40.7679 42.0158 41.5846 42.4999 42.3916L40.976 47.8086C40.9323 47.9017 40.8983 47.9968 40.8734 48.0929C39.6549 48.3 38.3728 49.1998 37.9811 50.461C37.623 51.6138 37.6551 52.5915 38.3845 53.4467C38.3845 53.4467 37.8396 51.6644 38.6266 50.2189Z"
        fill="#3D725F"
      />
      <path
        d="M14.5781 17.9413C14.5781 10.8108 20.3586 5.03027 27.4892 5.03027C34.6198 5.03027 40.4003 10.8108 40.4003 17.9413V18.2817C40.4003 23.6199 36.0728 27.9474 30.7345 27.9474H24.2439C18.9056 27.9474 14.5781 23.6199 14.5781 18.2817V17.9413Z"
        fill="#F99746"
      />
      <path
        opacity="0.9"
        d="M24.2441 27.9475C24.2441 27.9475 28.9397 28.1883 32.8153 27.1405C35.0253 26.543 36.5875 25.1638 37.8183 23.9934C42.0144 19.2325 38.3025 11.0016 38.3025 11.0016C38.3025 11.0016 40.4005 13.5839 40.4005 17.9414V18.2817C40.4005 23.62 36.073 27.9475 30.7348 27.9475H24.2441Z"
        fill="#D84C42"
      />
      <path
        d="M16.3535 17.7799C16.3535 11.6298 21.3392 6.6441 27.4893 6.6441C33.6395 6.6441 38.6251 11.6298 38.6251 17.7799V18.0291C38.6251 22.6155 34.9071 26.3335 30.3208 26.3335H24.6579C20.0715 26.3335 16.3535 22.6155 16.3535 18.0291V17.7799Z"
        fill="#F9F4EC"
      />
      <path
        d="M17.3223 17.836C17.3223 12.2206 21.8744 7.61224 27.4897 7.61224C33.1051 7.61224 37.6572 12.2206 37.6572 17.836C37.6572 21.9712 34.3049 25.365 30.1697 25.365H24.8098C20.6746 25.365 17.3223 21.9712 17.3223 17.836Z"
        fill="#282F33"
      />
      <path
        d="M18.6133 32.2594C18.6133 30.3237 20.0584 28.7545 21.841 28.7545H33.2996C35.0823 28.7545 36.5274 30.3237 36.5274 32.2594V45.8411C36.5274 51.2127 32.5172 60.3866 27.5703 60.3866C22.6235 60.3866 18.6133 51.2127 18.6133 45.8411V32.2594Z"
        fill="#F99746"
      />
      <path
        opacity="0.9"
        d="M35.9635 40.5834C35.9635 34.8879 36.2056 33.7487 35.7214 31.5582C34.8338 28.7542 33.3006 28.7542 33.3006 28.7542C35.0832 28.7542 36.5283 30.3234 36.5283 32.2592V45.8408C36.5283 51.2125 32.5181 60.3863 27.5713 60.3863C27.5713 60.3863 29.0297 59.9654 30.6377 58.371C32.7357 56.6185 34.3174 52.554 34.9144 51.0982C36.1249 47.6809 35.7214 46.1913 35.9635 40.5834Z"
        fill="#D84C42"
      />
      <path
        d="M30.3142 39.406C32.7653 39.406 34.7523 37.455 34.7523 35.0485C34.7523 32.6419 32.7653 30.691 30.3142 30.691C27.863 30.691 25.876 32.6419 25.876 35.0485C25.876 37.455 27.863 39.406 30.3142 39.406Z"
        fill="#FFA154"
      />
      <path
        d="M30.4725 31.3847C31.7935 31.3847 32.9494 31.9902 32.9494 32.6617C32.9494 33.0684 32.6521 33.2672 32.3109 33.2672C31.8045 33.2672 31.4411 32.7499 30.4064 32.7499C29.0193 32.7499 28.1936 33.7846 28.1936 35.0616C28.1936 36.3277 29.0193 37.351 30.4064 37.351C31.4411 37.351 31.8044 36.8445 32.3109 36.8445C32.6521 36.8445 32.9494 37.0316 32.9494 37.4499C32.9494 38.1105 31.7934 38.7269 30.4725 38.7269C28.1379 38.7268 26.7295 37.0755 26.7295 35.061C26.7288 33.0249 28.1379 31.3847 30.4725 31.3847Z"
        fill="white"
      />
      <path
        d="M29.779 35.7277C29.9118 35.7277 30.0417 35.6883 30.1521 35.6146C30.2625 35.5408 30.3486 35.4359 30.3994 35.3132C30.4503 35.1904 30.4636 35.0554 30.4377 34.9251C30.4118 34.7949 30.3478 34.6752 30.2539 34.5813C30.1599 34.4874 30.0403 34.4234 29.91 34.3975C29.7797 34.3716 29.6447 34.3849 29.522 34.4357C29.3993 34.4865 29.2944 34.5726 29.2206 34.6831C29.1468 34.7935 29.1074 34.9233 29.1074 35.0562C29.1074 35.2343 29.1782 35.4051 29.3041 35.531C29.4301 35.657 29.6009 35.7277 29.779 35.7277Z"
        fill="white"
      />
      <path
        d="M31.7302 35.7277C31.863 35.7277 31.9929 35.6883 32.1033 35.6145C32.2137 35.5407 32.2998 35.4358 32.3506 35.3131C32.4015 35.1904 32.4147 35.0554 32.3888 34.9251C32.3629 34.7948 32.2989 34.6752 32.205 34.5813C32.1111 34.4873 31.9914 34.4234 31.8612 34.3975C31.7309 34.3716 31.5959 34.3849 31.4732 34.4357C31.3504 34.4865 31.2456 34.5726 31.1718 34.6831C31.098 34.7935 31.0586 34.9233 31.0586 35.0562C31.0586 35.1444 31.076 35.2317 31.1097 35.3132C31.1435 35.3946 31.1929 35.4687 31.2553 35.531C31.3177 35.5934 31.3917 35.6429 31.4732 35.6766C31.5547 35.7104 31.642 35.7277 31.7302 35.7277Z"
        fill="white"
      />
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2965 39.3726C32.7942 39.3529 34.808 37.3757 34.794 34.9564C34.789 34.0597 34.4726 33.1878 33.9447 32.4662C34.6486 33.2676 35.069 34.2997 35.0744 35.3702C35.0885 37.8467 33.0751 39.8702 30.5775 39.8897C29.7439 39.8998 28.9239 39.6773 28.2079 39.2467C27.492 38.8161 26.9888 38.1329 26.6016 37.3885C27.03 37.9928 27.5166 38.5447 28.1742 38.8795C28.8317 39.2144 29.56 39.3835 30.2965 39.3726Z"
        fill="#D84C42"
      />
      <circle
        cx="26.3598"
        cy="21.653"
        r="0.322777"
        transform="rotate(180 26.3598 21.653)"
        fill="#BBE7FF"
      />
      <circle
        cx="24.7456"
        cy="20.8462"
        r="0.322777"
        transform="rotate(180 24.7456 20.8462)"
        fill="#BBE7FF"
      />
      <circle
        cx="25.5532"
        cy="21.653"
        r="0.322777"
        transform="rotate(180 25.5532 21.653)"
        fill="#BBE7FF"
      />
      <circle
        cx="27.1655"
        cy="21.653"
        r="0.322777"
        transform="rotate(180 27.1655 21.653)"
        fill="#BBE7FF"
      />
      <circle
        cx="25.5532"
        cy="20.8462"
        r="0.322777"
        transform="rotate(180 25.5532 20.8462)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 28.458 21.9758)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 30.0713 21.169)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 29.2646 21.9758)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 27.6514 21.9758)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 29.2646 21.169)"
        fill="#BBE7FF"
      />
      <circle cx="24.4234" cy="13.0989" r="0.322777" fill="#BBE7FF" />
      <circle cx="26.0366" cy="13.906" r="0.322777" fill="#BBE7FF" />
      <circle cx="26.0366" cy="15.52" r="0.322777" fill="#BBE7FF" />
      <circle cx="24.4234" cy="12.2925" r="0.322777" fill="#BBE7FF" />
      <circle cx="26.0366" cy="14.7132" r="0.322777" fill="#BBE7FF" />
      <circle cx="23.6167" cy="12.2925" r="0.322777" fill="#BBE7FF" />
      <circle cx="25.23" cy="13.0989" r="0.322777" fill="#BBE7FF" />
      <circle cx="26.8452" cy="15.52" r="0.322777" fill="#BBE7FF" />
      <circle cx="23.6167" cy="13.0989" r="0.322777" fill="#BBE7FF" />
      <circle cx="25.23" cy="13.906" r="0.322777" fill="#BBE7FF" />
      <circle cx="26.8452" cy="14.7132" r="0.322777" fill="#BBE7FF" />
      <circle cx="26.8452" cy="16.3274" r="0.322777" fill="#BBE7FF" />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 22.3262 12.7761)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 20.7119 13.5832)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 20.7119 15.1973)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 22.3262 11.9697)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 20.7119 14.3904)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 23.1328 11.9697)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 21.5186 12.7761)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 19.9043 15.1973)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 23.1328 12.7761)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 21.5186 13.5832)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 19.9043 14.3904)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 19.9043 16.0046)"
        fill="#BBE7FF"
      />
      <circle
        cx="22.0034"
        cy="18.7477"
        r="0.322777"
        transform="rotate(180 22.0034 18.7477)"
        fill="#BBE7FF"
      />
      <circle
        cx="20.3891"
        cy="17.9408"
        r="0.322777"
        transform="rotate(180 20.3891 17.9408)"
        fill="#BBE7FF"
      />
      <circle
        cx="20.3891"
        cy="16.3269"
        r="0.322777"
        transform="rotate(180 20.3891 16.3269)"
        fill="#BBE7FF"
      />
      <circle
        cx="22.0034"
        cy="19.5547"
        r="0.322777"
        transform="rotate(180 22.0034 19.5547)"
        fill="#BBE7FF"
      />
      <circle
        cx="20.3891"
        cy="17.1339"
        r="0.322777"
        transform="rotate(180 20.3891 17.1339)"
        fill="#BBE7FF"
      />
      <circle
        cx="22.81"
        cy="19.5547"
        r="0.322777"
        transform="rotate(180 22.81 19.5547)"
        fill="#BBE7FF"
      />
      <circle
        cx="21.1958"
        cy="18.7477"
        r="0.322777"
        transform="rotate(180 21.1958 18.7477)"
        fill="#BBE7FF"
      />
      <circle
        cx="22.81"
        cy="18.7477"
        r="0.322777"
        transform="rotate(180 22.81 18.7477)"
        fill="#BBE7FF"
      />
      <circle
        cx="21.1958"
        cy="17.9408"
        r="0.322777"
        transform="rotate(180 21.1958 17.9408)"
        fill="#BBE7FF"
      />
      <circle
        cx="19.5815"
        cy="17.1339"
        r="0.322777"
        transform="rotate(180 19.5815 17.1339)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 24.1006 19.0704)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 25.7139 18.2635)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 25.7139 16.6497)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 24.1006 19.8774)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 25.7139 17.4567)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 23.2939 19.8774)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 24.9072 19.0704)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 23.2939 19.0704)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 24.9072 18.2635)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 26.5225 17.4567)"
        fill="#BBE7FF"
      />
      <circle cx="33.3013" cy="13.0991" r="0.322777" fill="#BBE7FF" />
      <circle cx="34.9136" cy="13.9058" r="0.322777" fill="#BBE7FF" />
      <circle cx="34.9136" cy="15.5198" r="0.322777" fill="#BBE7FF" />
      <circle cx="33.3013" cy="12.2924" r="0.322777" fill="#BBE7FF" />
      <circle cx="34.9136" cy="14.713" r="0.322777" fill="#BBE7FF" />
      <circle cx="32.4937" cy="12.2924" r="0.322777" fill="#BBE7FF" />
      <circle cx="34.107" cy="13.0991" r="0.322777" fill="#BBE7FF" />
      <circle cx="35.7222" cy="15.5198" r="0.322777" fill="#BBE7FF" />
      <circle cx="32.4937" cy="13.0991" r="0.322777" fill="#BBE7FF" />
      <circle cx="34.107" cy="13.9058" r="0.322777" fill="#BBE7FF" />
      <circle cx="35.7222" cy="14.713" r="0.322777" fill="#BBE7FF" />
      <circle cx="35.7222" cy="16.3269" r="0.322777" fill="#BBE7FF" />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 31.2031 12.7763)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 29.5889 13.5831)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 29.5889 15.197)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 31.2031 11.9696)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 29.5889 14.3903)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 32.0098 11.9696)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 30.3955 12.7763)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 28.7812 15.197)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 32.0098 12.7763)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 30.3955 13.5831)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 28.7812 14.3903)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(-1 0 0 1 28.7812 16.0042)"
        fill="#BBE7FF"
      />
      <circle
        cx="30.8803"
        cy="18.7475"
        r="0.322777"
        transform="rotate(180 30.8803 18.7475)"
        fill="#BBE7FF"
      />
      <circle
        cx="29.2661"
        cy="17.9408"
        r="0.322777"
        transform="rotate(180 29.2661 17.9408)"
        fill="#BBE7FF"
      />
      <circle
        cx="29.2661"
        cy="16.3266"
        r="0.322777"
        transform="rotate(180 29.2661 16.3266)"
        fill="#BBE7FF"
      />
      <circle
        cx="30.8803"
        cy="19.5547"
        r="0.322777"
        transform="rotate(180 30.8803 19.5547)"
        fill="#BBE7FF"
      />
      <circle
        cx="29.2661"
        cy="17.1338"
        r="0.322777"
        transform="rotate(180 29.2661 17.1338)"
        fill="#BBE7FF"
      />
      <circle
        cx="31.687"
        cy="19.5547"
        r="0.322777"
        transform="rotate(180 31.687 19.5547)"
        fill="#BBE7FF"
      />
      <circle
        cx="30.0727"
        cy="18.7475"
        r="0.322777"
        transform="rotate(180 30.0727 18.7475)"
        fill="#BBE7FF"
      />
      <circle
        cx="31.687"
        cy="18.7475"
        r="0.322777"
        transform="rotate(180 31.687 18.7475)"
        fill="#BBE7FF"
      />
      <circle
        cx="30.0727"
        cy="17.9408"
        r="0.322777"
        transform="rotate(180 30.0727 17.9408)"
        fill="#BBE7FF"
      />
      <circle
        cx="28.4585"
        cy="17.1338"
        r="0.322777"
        transform="rotate(180 28.4585 17.1338)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 32.9785 19.0703)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 34.5908 18.2635)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 34.5908 16.6494)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 32.9785 19.8775)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 34.5908 17.4565)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 32.1709 19.8775)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 33.7842 19.0703)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 32.1709 19.0703)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 33.7842 18.2635)"
        fill="#BBE7FF"
      />
      <circle
        cx="0.322777"
        cy="0.322777"
        r="0.322777"
        transform="matrix(1 0 0 -1 35.3994 17.4565)"
        fill="#BBE7FF"
      />
      <path
        d="M24.3185 13.7449C24.3185 13.7449 24.3751 14.2726 24.5953 14.4928C24.8155 14.713 25.3432 14.7695 25.3432 14.7695C25.3432 14.7695 24.8155 14.8261 24.5953 15.0463C24.3751 15.2665 24.3185 15.7941 24.3185 15.7941C24.3185 15.7941 24.262 15.2665 24.0418 15.0463C23.8216 14.8261 23.2939 14.7695 23.2939 14.7695C23.2939 14.7695 23.8216 14.713 24.0418 14.4928C24.262 14.2726 24.3185 13.7449 24.3185 13.7449Z"
        fill="white"
      />
      <path
        d="M33.3566 13.7449C33.3566 13.7449 33.4132 14.2726 33.6334 14.4928C33.8536 14.713 34.3812 14.7695 34.3812 14.7695C34.3812 14.7695 33.8536 14.8261 33.6334 15.0463C33.4132 15.2665 33.3566 15.7941 33.3566 15.7941C33.3566 15.7941 33.3001 15.2665 33.0799 15.0463C32.8597 14.8261 32.332 14.7695 32.332 14.7695C32.332 14.7695 32.8597 14.713 33.0799 14.4928C33.3001 14.2726 33.3566 13.7449 33.3566 13.7449Z"
        fill="white"
      />
    </g>
  </svg>
);

export default AIBotSvg;
