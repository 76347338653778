import React from 'react';

export const WaveLandingPageDiv: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="1280"
    height="98"
    viewBox="0 0 1280 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1280.12 0.360176V11.0197C1280.12 11.0197 1103.24 71.8342 977.344 73.5708C914.806 74.4335 870.113 64.5898 826.474 54.9782C777.564 44.2059 729.977 33.7249 660.074 38.9343C605.964 42.9667 562.394 55.2689 518.329 67.7106C478.616 78.9237 438.502 90.2502 389.909 95.7382C235.116 113.22 0.117188 11.0197 0.117188 11.0197V0.360176H1280.12Z"
      fill="#FFA154"
    />
  </svg>
);

export default WaveLandingPageDiv;
