import React from 'react';

export const WaveLandingPageDiv3: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="1281"
    height="109"
    viewBox="0 0 1281 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1280.36 0.303955V62.1259C1280.36 62.1259 1218.79 11.1592 1092.89 12.8958C1030.35 13.7585 921.709 61.6213 855.628 72.2056C721.516 93.6865 571.573 43.6542 486.077 35.9107C403.434 28.4255 330.02 34.6115 231.712 49.8691C139.643 64.1583 0.361328 108.158 0.361328 108.158V0.303955H1280.36Z"
      fill="#FFCA9D"
    />
  </svg>
);

export default WaveLandingPageDiv3;
