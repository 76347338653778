import React from 'react';

export const StarLandingPageBackground: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="1280"
    height="552"
    viewBox="0 0 1280 552"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M478.71 410.855C478.71 410.855 481.902 441.097 494.336 453.717C506.769 466.338 536.562 469.578 536.562 469.578C536.562 469.578 506.769 472.818 494.336 485.438C481.902 498.059 478.71 528.301 478.71 528.301C478.71 528.301 475.518 498.059 463.085 485.438C450.652 472.818 420.858 469.578 420.858 469.578C420.858 469.578 450.652 466.338 463.085 453.717C475.518 441.097 478.71 410.855 478.71 410.855Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M1189.2 92.1765C1189.2 92.1765 1192.79 126.197 1206.78 140.394C1220.76 154.592 1254.28 158.237 1254.28 158.237C1254.28 158.237 1220.76 161.881 1206.78 176.079C1192.79 190.276 1189.2 224.297 1189.2 224.297C1189.2 224.297 1185.61 190.276 1171.62 176.079C1157.64 161.881 1124.12 158.237 1124.12 158.237C1124.12 158.237 1157.64 154.592 1171.62 140.394C1185.61 126.197 1189.2 92.1765 1189.2 92.1765Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M359.637 0.109619C359.637 0.109619 363.227 34.1301 377.214 48.3275C391.201 62.5248 424.717 66.1697 424.717 66.1697C424.717 66.1697 391.201 69.8146 377.214 84.0119C363.227 98.2092 359.637 132.23 359.637 132.23C359.637 132.23 356.046 98.2092 342.059 84.0119C328.072 69.8146 294.557 66.1697 294.557 66.1697C294.557 66.1697 328.072 62.5248 342.059 48.3275C356.046 34.1301 359.637 0.109619 359.637 0.109619Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M684.952 177.669C684.952 177.669 687.487 201.682 697.359 211.703C707.232 221.724 730.888 224.297 730.888 224.297C730.888 224.297 707.232 226.87 697.359 236.891C687.487 246.912 684.952 270.924 684.952 270.924C684.952 270.924 682.418 246.912 672.546 236.891C662.673 226.87 639.017 224.297 639.017 224.297C639.017 224.297 662.673 221.724 672.546 211.703C682.418 201.682 684.952 177.669 684.952 177.669Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M110.417 210.926C110.417 210.926 112.952 234.939 122.824 244.96C132.696 254.981 156.353 257.554 156.353 257.554C156.353 257.554 132.696 260.126 122.824 270.147C112.952 280.168 110.417 304.181 110.417 304.181C110.417 304.181 107.883 280.168 98.0104 270.147C88.1381 260.126 64.4814 257.554 64.4814 257.554C64.4814 257.554 88.1381 254.981 98.0104 244.96C107.883 234.939 110.417 210.926 110.417 210.926Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M944.327 121.586C944.327 121.586 946.862 145.599 956.734 155.62C966.607 165.641 990.263 168.214 990.263 168.214C990.263 168.214 966.607 170.786 956.734 180.807C946.862 190.828 944.327 214.841 944.327 214.841C944.327 214.841 941.793 190.828 931.921 180.807C922.048 170.786 898.392 168.214 898.392 168.214C898.392 168.214 922.048 165.641 931.921 155.62C941.793 145.599 944.327 121.586 944.327 121.586Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M369.791 154.843C369.791 154.843 372.326 178.855 382.198 188.876C392.07 198.897 415.727 201.47 415.727 201.47C415.727 201.47 392.07 204.043 382.198 214.064C372.326 224.085 369.791 248.098 369.791 248.098C369.791 248.098 367.257 224.085 357.384 214.064C347.512 204.043 323.855 201.47 323.855 201.47C323.855 201.47 347.512 198.897 357.384 188.876C367.257 178.855 369.791 154.843 369.791 154.843Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M754.455 335.879C754.455 335.879 756.567 355.894 764.796 364.247C773.025 372.6 792.743 374.744 792.743 374.744C792.743 374.744 773.025 376.888 764.796 385.241C756.567 393.594 754.455 413.609 754.455 413.609C754.455 413.609 752.342 393.594 744.113 385.241C735.884 376.888 716.166 374.744 716.166 374.744C716.166 374.744 735.884 372.6 744.113 364.247C752.342 355.894 754.455 335.879 754.455 335.879Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M524.059 210.56C524.059 210.56 526.172 230.575 534.401 238.928C542.629 247.281 562.348 249.425 562.348 249.425C562.348 249.425 542.629 251.57 534.401 259.922C526.172 268.275 524.059 288.29 524.059 288.29C524.059 288.29 521.947 268.275 513.718 259.922C505.489 251.57 485.771 249.425 485.771 249.425C485.771 249.425 505.489 247.281 513.718 238.928C521.947 230.575 524.059 210.56 524.059 210.56Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M1281.19 391.848C1281.19 391.848 1283.3 411.863 1291.53 420.216C1299.76 428.569 1319.48 430.713 1319.48 430.713C1319.48 430.713 1299.76 432.858 1291.53 441.21C1283.3 449.563 1281.19 469.579 1281.19 469.579C1281.19 469.579 1279.07 449.563 1270.85 441.21C1262.62 432.858 1242.9 430.713 1242.9 430.713C1242.9 430.713 1262.62 428.569 1270.85 420.216C1279.07 411.863 1281.19 391.848 1281.19 391.848Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M903.057 313.807C903.057 313.807 905.17 333.822 913.399 342.175C921.627 350.528 941.346 352.672 941.346 352.672C941.346 352.672 921.627 354.816 913.399 363.169C905.17 371.522 903.057 391.537 903.057 391.537C903.057 391.537 900.945 371.522 892.716 363.169C884.487 354.816 864.769 352.672 864.769 352.672C864.769 352.672 884.487 350.528 892.716 342.175C900.945 333.822 903.057 313.807 903.057 313.807Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M328.522 347.063C328.522 347.063 330.635 367.079 338.863 375.432C347.092 383.784 366.811 385.929 366.811 385.929C366.811 385.929 347.092 388.073 338.863 396.426C330.635 404.779 328.522 424.794 328.522 424.794C328.522 424.794 326.409 404.779 318.181 396.426C309.952 388.073 290.233 385.929 290.233 385.929C290.233 385.929 309.952 383.784 318.181 375.432C326.409 367.079 328.522 347.063 328.522 347.063Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M1162.41 257.723C1162.41 257.723 1164.52 277.739 1172.75 286.092C1180.98 294.444 1200.7 296.589 1200.7 296.589C1200.7 296.589 1180.98 298.733 1172.75 307.086C1164.52 315.439 1162.41 335.454 1162.41 335.454C1162.41 335.454 1160.3 315.439 1152.07 307.086C1143.84 298.733 1124.12 296.589 1124.12 296.589C1124.12 296.589 1143.84 294.444 1152.07 286.092C1160.3 277.739 1162.41 257.723 1162.41 257.723Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M587.874 290.98C587.874 290.98 589.986 310.995 598.215 319.348C606.444 327.701 626.162 329.845 626.162 329.845C626.162 329.845 606.444 331.99 598.215 340.342C589.986 348.695 587.874 368.711 587.874 368.711C587.874 368.711 585.761 348.695 577.532 340.342C569.303 331.99 549.585 329.845 549.585 329.845C549.585 329.845 569.303 327.701 577.532 319.348C585.761 310.995 587.874 290.98 587.874 290.98Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M1067.71 383.682C1067.71 383.682 1069.56 401.171 1076.75 408.469C1083.94 415.767 1101.16 417.641 1101.16 417.641C1101.16 417.641 1083.94 419.515 1076.75 426.813C1069.56 434.111 1067.71 451.6 1067.71 451.6C1067.71 451.6 1065.86 434.111 1058.67 426.813C1051.48 419.515 1034.25 417.641 1034.25 417.641C1034.25 417.641 1051.48 415.767 1058.67 408.469C1065.86 401.171 1067.71 383.682 1067.71 383.682Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M777.616 277.851C777.616 277.851 779.462 295.34 786.652 302.638C793.842 309.936 811.071 311.81 811.071 311.81C811.071 311.81 793.842 313.683 786.652 320.982C779.462 328.28 777.616 345.768 777.616 345.768C777.616 345.768 775.77 328.28 768.58 320.982C761.39 313.683 744.161 311.81 744.161 311.81C744.161 311.81 761.39 309.936 768.58 302.638C775.77 295.34 777.616 277.851 777.616 277.851Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M1062.13 146.924C1062.13 146.924 1063.97 164.412 1071.16 171.711C1078.35 179.009 1095.58 180.882 1095.58 180.882C1095.58 180.882 1078.35 182.756 1071.16 190.054C1063.97 197.353 1062.13 214.841 1062.13 214.841C1062.13 214.841 1060.28 197.353 1053.09 190.054C1045.9 182.756 1028.67 180.882 1028.67 180.882C1028.67 180.882 1045.9 179.009 1053.09 171.711C1060.28 164.412 1062.13 146.924 1062.13 146.924Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M562.203 42.1267C562.203 42.1267 564.049 59.6152 571.239 66.9134C578.429 74.2117 595.658 76.0854 595.658 76.0854C595.658 76.0854 578.429 77.959 571.239 85.2573C564.049 92.5555 562.203 110.044 562.203 110.044C562.203 110.044 560.357 92.5555 553.167 85.2573C545.977 77.959 528.748 76.0854 528.748 76.0854C528.748 76.0854 545.977 74.2117 553.167 66.9134C560.357 59.6152 562.203 42.1267 562.203 42.1267Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M936.833 483.75C936.833 483.75 938.679 501.238 945.869 508.536C953.059 515.835 970.288 517.708 970.288 517.708C970.288 517.708 953.059 519.582 945.869 526.88C938.679 534.179 936.833 551.667 936.833 551.667C936.833 551.667 934.987 534.179 927.797 526.88C920.607 519.582 903.378 517.708 903.378 517.708C903.378 517.708 920.607 515.835 927.797 508.536C934.987 501.238 936.833 483.75 936.833 483.75Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
    <path
      d="M1196.18 427.666C1196.18 427.666 1198.03 445.155 1205.22 452.453C1212.41 459.751 1229.64 461.625 1229.64 461.625C1229.64 461.625 1212.41 463.498 1205.22 470.797C1198.03 478.095 1196.18 495.583 1196.18 495.583C1196.18 495.583 1194.34 478.095 1187.15 470.797C1179.96 463.498 1162.73 461.625 1162.73 461.625C1162.73 461.625 1179.96 459.751 1187.15 452.453C1194.34 445.155 1196.18 427.666 1196.18 427.666Z"
      fill="#FFA154"
      fillOpacity="0.13"
    />
  </svg>
);

export default StarLandingPageBackground;
