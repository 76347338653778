import React from 'react';

export const QuoteSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="57"
    height="45"
    viewBox="0 0 57 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8672 22.4976C15.8005 22.7642 18.2005 23.8976 20.0672 25.8976C21.9339 27.8976 22.8672 30.4309 22.8672 33.4976C22.8672 36.8309 21.8672 39.5642 19.8672 41.6976C17.8672 43.6976 15.3339 44.6976 12.2672 44.6976C8.80052 44.6976 6.00052 43.4309 3.86719 40.8976C1.86719 38.3642 0.867188 34.4309 0.867188 29.0976C0.867188 18.8309 4.26719 9.96422 11.0672 2.49756C12.4005 1.16423 13.9339 0.497559 15.6672 0.497559C17.1339 0.497559 18.4005 1.03089 19.4672 2.09756C20.5339 3.03089 21.0672 4.16422 21.0672 5.49755C21.0672 6.96422 20.5339 8.23089 19.4672 9.29756C16.0005 12.8976 13.8005 17.2976 12.8672 22.4976ZM46.0672 22.4976C49.0005 22.7642 51.4005 23.8976 53.2672 25.8976C55.1339 27.8976 56.0672 30.4309 56.0672 33.4976C56.0672 36.8309 55.0672 39.5642 53.0672 41.6976C51.0672 43.6976 48.5339 44.6976 45.4672 44.6976C42.0005 44.6976 39.2005 43.4309 37.0672 40.8976C35.0672 38.3642 34.0672 34.4309 34.0672 29.0976C34.0672 18.8309 37.4672 9.96422 44.2672 2.49756C45.6005 1.16423 47.1339 0.497559 48.8672 0.497559C50.3339 0.497559 51.6005 1.03089 52.6672 2.09756C53.7339 3.03089 54.2672 4.16422 54.2672 5.49755C54.2672 6.96422 53.7339 8.23089 52.6672 9.29756C49.2005 12.8976 47.0005 17.2976 46.0672 22.4976Z"
      fill="currentColor"
    />
  </svg>
);

export default QuoteSvg;
