import React from 'react';

export const StarLandingPageBackgroundWhite: React.FC<React.SVGProps<
  SVGSVGElement
>> = () => (
  <svg
    width="1280"
    height="712"
    viewBox="0 0 1280 712"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M445.237 414.704C445.237 414.704 448.429 444.946 460.862 457.566C473.295 470.187 503.089 473.427 503.089 473.427C503.089 473.427 473.295 476.667 460.862 489.288C448.429 501.908 445.237 532.15 445.237 532.15C445.237 532.15 442.045 501.908 429.611 489.288C417.178 476.667 387.385 473.427 387.385 473.427C387.385 473.427 417.178 470.187 429.611 457.566C442.045 444.946 445.237 414.704 445.237 414.704Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1155.73 96.0256C1155.73 96.0256 1159.32 130.046 1173.3 144.243C1187.29 158.441 1220.81 162.086 1220.81 162.086C1220.81 162.086 1187.29 165.731 1173.3 179.928C1159.32 194.125 1155.73 228.146 1155.73 228.146C1155.73 228.146 1152.14 194.125 1138.15 179.928C1124.16 165.731 1090.65 162.086 1090.65 162.086C1090.65 162.086 1124.16 158.441 1138.15 144.243C1152.14 130.046 1155.73 96.0256 1155.73 96.0256Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M193.233 0.216309C193.233 0.216309 196.824 34.2368 210.811 48.4342C224.797 62.6315 258.313 66.2764 258.313 66.2764C258.313 66.2764 224.797 69.9213 210.811 84.1186C196.824 98.3159 193.233 132.336 193.233 132.336C193.233 132.336 189.642 98.3159 175.656 84.1186C161.669 69.9213 128.153 66.2764 128.153 66.2764C128.153 66.2764 161.669 62.6315 175.656 48.4342C189.642 34.2368 193.233 0.216309 193.233 0.216309Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M651.479 181.518C651.479 181.518 654.013 205.531 663.886 215.552C673.758 225.573 697.415 228.146 697.415 228.146C697.415 228.146 673.758 230.719 663.886 240.74C654.013 250.761 651.479 274.774 651.479 274.774C651.479 274.774 648.944 250.761 639.072 240.74C629.2 230.719 605.543 228.146 605.543 228.146C605.543 228.146 629.2 225.573 639.072 215.552C648.944 205.531 651.479 181.518 651.479 181.518Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M76.9436 214.775C76.9436 214.775 79.4782 238.788 89.3505 248.809C99.2228 258.83 122.879 261.403 122.879 261.403C122.879 261.403 99.2228 263.975 89.3505 273.996C79.4782 284.018 76.9436 308.03 76.9436 308.03C76.9436 308.03 74.4091 284.018 64.5368 273.996C54.6645 263.975 31.0078 261.403 31.0078 261.403C31.0078 261.403 54.6645 258.83 64.5368 248.809C74.4091 238.788 76.9436 214.775 76.9436 214.775Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M910.854 125.435C910.854 125.435 913.388 149.448 923.261 159.469C933.133 169.49 956.79 172.063 956.79 172.063C956.79 172.063 933.133 174.635 923.261 184.656C913.388 194.677 910.854 218.69 910.854 218.69C910.854 218.69 908.319 194.677 898.447 184.656C888.575 174.635 864.918 172.063 864.918 172.063C864.918 172.063 888.575 169.49 898.447 159.469C908.319 149.448 910.854 125.435 910.854 125.435Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1017.29 37.6849C1017.29 37.6849 1018.85 52.4093 1024.9 58.5541C1030.95 64.6988 1045.46 66.2764 1045.46 66.2764C1045.46 66.2764 1030.95 67.8539 1024.9 73.9987C1018.85 80.1434 1017.29 94.8679 1017.29 94.8679C1017.29 94.8679 1015.74 80.1434 1009.68 73.9987C1003.63 67.8539 989.125 66.2764 989.125 66.2764C989.125 66.2764 1003.63 64.6988 1009.68 58.5541C1015.74 52.4093 1017.29 37.6849 1017.29 37.6849Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M450.95 654.285C450.95 654.285 452.505 669.009 458.558 675.154C464.612 681.299 479.118 682.877 479.118 682.877C479.118 682.877 464.612 684.454 458.558 690.599C452.505 696.744 450.95 711.468 450.95 711.468C450.95 711.468 449.396 696.744 443.343 690.599C437.289 684.454 422.783 682.877 422.783 682.877C422.783 682.877 437.289 681.299 443.343 675.154C449.396 669.009 450.95 654.285 450.95 654.285Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M336.318 158.692C336.318 158.692 338.852 182.705 348.725 192.726C358.597 202.747 382.254 205.319 382.254 205.319C382.254 205.319 358.597 207.892 348.725 217.913C338.852 227.934 336.318 251.947 336.318 251.947C336.318 251.947 333.783 227.934 323.911 217.913C314.038 207.892 290.382 205.319 290.382 205.319C290.382 205.319 314.038 202.747 323.911 192.726C333.783 182.705 336.318 158.692 336.318 158.692Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1306.88 459.077C1306.88 459.077 1308.47 474.198 1314.69 480.508C1320.91 486.818 1335.8 488.438 1335.8 488.438C1335.8 488.438 1320.91 490.058 1314.69 496.369C1308.47 502.679 1306.88 517.8 1306.88 517.8C1306.88 517.8 1305.28 502.679 1299.06 496.369C1292.85 490.058 1277.95 488.438 1277.95 488.438C1277.95 488.438 1292.85 486.818 1299.06 480.508C1305.28 474.198 1306.88 459.077 1306.88 459.077Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1295.52 306.471C1295.52 306.471 1297.63 326.487 1305.86 334.839C1314.09 343.192 1333.8 345.336 1333.8 345.336C1333.8 345.336 1314.09 347.481 1305.86 355.834C1297.63 364.186 1295.52 384.202 1295.52 384.202C1295.52 384.202 1293.4 364.186 1285.17 355.834C1276.95 347.481 1257.23 345.336 1257.23 345.336C1257.23 345.336 1276.95 343.192 1285.17 334.839C1293.4 326.487 1295.52 306.471 1295.52 306.471Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M335.537 520.923C335.537 520.923 337.649 540.938 345.878 549.291C354.107 557.644 373.825 559.788 373.825 559.788C373.825 559.788 354.107 561.933 345.878 570.285C337.649 578.638 335.537 598.653 335.537 598.653C335.537 598.653 333.424 578.638 325.195 570.285C316.966 561.933 297.248 559.788 297.248 559.788C297.248 559.788 316.966 557.644 325.195 549.291C333.424 540.938 335.537 520.923 335.537 520.923Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M720.981 339.728C720.981 339.728 723.094 359.743 731.322 368.096C739.551 376.449 759.27 378.593 759.27 378.593C759.27 378.593 739.551 380.737 731.322 389.09C723.094 397.443 720.981 417.458 720.981 417.458C720.981 417.458 718.868 397.443 710.64 389.09C702.411 380.737 682.692 378.593 682.692 378.593C682.692 378.593 702.411 376.449 710.64 368.096C718.868 359.743 720.981 339.728 720.981 339.728Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M490.586 214.409C490.586 214.409 492.698 234.424 500.927 242.777C509.156 251.13 528.874 253.274 528.874 253.274C528.874 253.274 509.156 255.419 500.927 263.771C492.698 272.124 490.586 292.14 490.586 292.14C490.586 292.14 488.473 272.124 480.244 263.771C472.015 255.419 452.297 253.274 452.297 253.274C452.297 253.274 472.015 251.13 480.244 242.777C488.473 234.424 490.586 214.409 490.586 214.409Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1247.71 395.697C1247.71 395.697 1249.83 415.712 1258.05 424.065C1266.28 432.418 1286 434.562 1286 434.562C1286 434.562 1266.28 436.707 1258.05 445.059C1249.83 453.412 1247.71 473.428 1247.71 473.428C1247.71 473.428 1245.6 453.412 1237.37 445.059C1229.14 436.707 1209.42 434.562 1209.42 434.562C1209.42 434.562 1229.14 432.418 1237.37 424.065C1245.6 415.712 1247.71 395.697 1247.71 395.697Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M869.584 317.656C869.584 317.656 871.696 337.671 879.925 346.024C888.154 354.377 907.872 356.521 907.872 356.521C907.872 356.521 888.154 358.665 879.925 367.018C871.696 375.371 869.584 395.386 869.584 395.386C869.584 395.386 867.471 375.371 859.242 367.018C851.013 358.665 831.295 356.521 831.295 356.521C831.295 356.521 851.013 354.377 859.242 346.024C867.471 337.671 869.584 317.656 869.584 317.656Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M295.048 350.913C295.048 350.913 297.161 370.928 305.39 379.281C313.619 387.633 333.337 389.778 333.337 389.778C333.337 389.778 313.619 391.922 305.39 400.275C297.161 408.628 295.048 428.643 295.048 428.643C295.048 428.643 292.936 408.628 284.707 400.275C276.478 391.922 256.76 389.778 256.76 389.778C256.76 389.778 276.478 387.633 284.707 379.281C292.936 370.928 295.048 350.913 295.048 350.913Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1128.94 261.573C1128.94 261.573 1131.05 281.588 1139.28 289.941C1147.51 298.293 1167.22 300.438 1167.22 300.438C1167.22 300.438 1147.51 302.582 1139.28 310.935C1131.05 319.288 1128.94 339.303 1128.94 339.303C1128.94 339.303 1126.82 319.288 1118.59 310.935C1110.36 302.582 1090.65 300.438 1090.65 300.438C1090.65 300.438 1110.36 298.293 1118.59 289.941C1126.82 281.588 1128.94 261.573 1128.94 261.573Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M554.4 294.829C554.4 294.829 556.513 314.844 564.741 323.197C572.97 331.55 592.689 333.694 592.689 333.694C592.689 333.694 572.97 335.839 564.741 344.192C556.513 352.544 554.4 372.56 554.4 372.56C554.4 372.56 552.287 352.544 544.059 344.192C535.83 335.839 516.111 333.694 516.111 333.694C516.111 333.694 535.83 331.55 544.059 323.197C552.287 314.844 554.4 294.829 554.4 294.829Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1034.24 387.531C1034.24 387.531 1036.08 405.02 1043.27 412.318C1050.46 419.616 1067.69 421.49 1067.69 421.49C1067.69 421.49 1050.46 423.364 1043.27 430.662C1036.08 437.96 1034.24 455.449 1034.24 455.449C1034.24 455.449 1032.39 437.96 1025.2 430.662C1018.01 423.364 1000.78 421.49 1000.78 421.49C1000.78 421.49 1018.01 419.616 1025.2 412.318C1032.39 405.02 1034.24 387.531 1034.24 387.531Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M744.142 281.7C744.142 281.7 745.988 299.189 753.178 306.487C760.368 313.785 777.597 315.659 777.597 315.659C777.597 315.659 760.368 317.533 753.178 324.831C745.988 332.129 744.142 349.618 744.142 349.618C744.142 349.618 742.296 332.129 735.106 324.831C727.917 317.533 710.688 315.659 710.688 315.659C710.688 315.659 727.917 313.785 735.106 306.487C742.296 299.189 744.142 281.7 744.142 281.7Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1028.65 150.773C1028.65 150.773 1030.5 168.261 1037.69 175.56C1044.88 182.858 1062.11 184.732 1062.11 184.732C1062.11 184.732 1044.88 186.605 1037.69 193.904C1030.5 201.202 1028.65 218.69 1028.65 218.69C1028.65 218.69 1026.81 201.202 1019.62 193.904C1012.43 186.605 995.198 184.732 995.198 184.732C995.198 184.732 1012.43 182.858 1019.62 175.56C1026.81 168.261 1028.65 150.773 1028.65 150.773Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M435.178 0.216309C435.178 0.216309 437.024 17.7048 444.214 25.003C451.404 32.3013 468.633 34.175 468.633 34.175C468.633 34.175 451.404 36.0486 444.214 43.3469C437.024 50.6451 435.178 68.1336 435.178 68.1336C435.178 68.1336 433.333 50.6451 426.143 43.3469C418.953 36.0486 401.724 34.175 401.724 34.175C401.724 34.175 418.953 32.3013 426.143 25.003C433.333 17.7048 435.178 0.216309 435.178 0.216309Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M173.875 586.368C173.875 586.368 175.721 603.856 182.911 611.155C190.101 618.453 207.33 620.326 207.33 620.326C207.33 620.326 190.101 622.2 182.911 629.498C175.721 636.797 173.875 654.285 173.875 654.285C173.875 654.285 172.029 636.797 164.839 629.498C157.649 622.2 140.42 620.326 140.42 620.326C140.42 620.326 157.649 618.453 164.839 611.155C172.029 603.856 173.875 586.368 173.875 586.368Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M903.359 487.599C903.359 487.599 905.205 505.087 912.395 512.386C919.585 519.684 936.814 521.558 936.814 521.558C936.814 521.558 919.585 523.431 912.395 530.729C905.205 538.028 903.359 555.516 903.359 555.516C903.359 555.516 901.513 538.028 894.323 530.729C887.133 523.431 869.904 521.558 869.904 521.558C869.904 521.558 887.133 519.684 894.323 512.386C901.513 505.087 903.359 487.599 903.359 487.599Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1162.71 431.515C1162.71 431.515 1164.56 449.004 1171.75 456.302C1178.94 463.6 1196.17 465.474 1196.17 465.474C1196.17 465.474 1178.94 467.347 1171.75 474.646C1164.56 481.944 1162.71 499.432 1162.71 499.432C1162.71 499.432 1160.86 481.944 1153.67 474.646C1146.48 467.347 1129.26 465.474 1129.26 465.474C1129.26 465.474 1146.48 463.6 1153.67 456.302C1160.86 449.004 1162.71 431.515 1162.71 431.515Z"
      fill="white"
      fillOpacity="0.18"
    />
    <path
      d="M1026.34 598.653C1026.34 598.653 1028.19 616.142 1035.38 623.44C1042.57 630.738 1059.79 632.612 1059.79 632.612C1059.79 632.612 1042.57 634.486 1035.38 641.784C1028.19 649.082 1026.34 666.571 1026.34 666.571C1026.34 666.571 1024.49 649.082 1017.3 641.784C1010.11 634.486 992.885 632.612 992.885 632.612C992.885 632.612 1010.11 630.738 1017.3 623.44C1024.49 616.142 1026.34 598.653 1026.34 598.653Z"
      fill="white"
      fillOpacity="0.18"
    />
  </svg>
);

export default StarLandingPageBackgroundWhite;
