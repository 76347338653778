import React from 'react';

export const WaveLandingPageDiv2: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="1280"
    height="109"
    viewBox="0 0 1280 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1280.12 0.477539V62.2995C1280.12 62.2995 1218.55 11.3327 1092.65 13.0694C1030.11 13.932 921.465 61.7949 855.384 72.3792C721.272 93.8601 571.328 43.8278 485.833 36.0843C403.189 28.5991 329.776 34.7851 231.468 50.0426C139.398 64.3318 0.117188 108.331 0.117188 108.331V0.477539H1280.12Z"
      fill="#FFA154"
    />
  </svg>
);

export default WaveLandingPageDiv2;
